<template>
  <div class="c-join-sparrow">
    <div class="c-join-sparrow__title c-join-sparrow__title_desktop">
      <h1 class="c-join-sparrow__title-text">Join Sparrow and make an impact</h1>
      <h2 class="c-join-sparrow__title-subtitle">in a fast-growing and innovative industry</h2>
    </div>
    <div class="c-join-sparrow__title c-join-sparrow__title_mobile">
      <h1 class="c-join-sparrow__title-text">Join Sparrow and<br> make an impact</h1>
      <h2 class="c-join-sparrow__title-subtitle">in a fast-growing and innovative industry</h2>
    </div>
    <div class="c-join-sparrow__description">
      <p class="c-join-sparrow__description-text">
        Sparrow is a home-grown Singapore digital assets platform that offers an innovative and intuitive trading platform for individuals and bespoke digital asset solutions for and institutions. Established in 2018, Sparrow has partnered with experienced digital assets names and achieved numerous milestones.
        <br> <br>
        We embrace four core values: <span class="c-join-sparrow__description-text_bold">collaborate</span>, <span class="c-join-sparrow__description-text_bold">empower</span>, <span class="c-join-sparrow__description-text_bold">respect</span> and be <span class="c-join-sparrow__description-text_bold">responsible</span>. Our dynamic team fosters a diverse culture through engagement, innovation and people development. Be part of a fast-growing and innovative business in the digital assets industry where we value our people and foster a positive work culture.
      </p>
    </div>
    <div class="c-join-sparrow__position">
      <h1 class="c-join-sparrow__title-text">Current available positions</h1>

      <div class="c-join-sparrow__job-container">
        <!-- <JobTechnology></JobTechnology> -->
        <JobCompliance></JobCompliance>
        <!-- <JobFinance></JobFinance>
        <JobMarketing></JobMarketing>
        <JobHR></JobHR> -->
        <!-- <JobTrading></JobTrading> -->
      </div>
      <div class="c-join-sparrow__position-email">
        To find out more about current opening, email us at: <a class="c-join-sparrow__position-email-link" href="mailto:hr@sparrowexchange.com">hr@sparrowexchange.com</a>
      </div>

    </div>
    <modal v-if="isModalVisible" :duties="userSelected.duties" :requirements="userSelected.requirements" :namePos="userSelected.namePos" :spReq="userSelected.specialReq" :description="userSelected.description" @close="closeModal">
    </modal>
  </div>
</template>

<script>
  import $ from 'jquery'
  // import JobTechnology from '@/components/peopleatsparrow/jobs/Technology.vue'
  import JobCompliance from '@/components/peopleatsparrow/jobs/Compliance.vue'
  // import JobFinance from '@/components/peopleatsparrow/jobs/Finance.vue'
  // import JobMarketing from '@/components/peopleatsparrow/jobs/Marketing.vue'
  // import JobHR from '@/components/peopleatsparrow/jobs/HumanResources.vue'
  // import JobTrading from '@/components/peopleatsparrow/jobs/Trading.vue'
  import modal from '@/components/peopleatsparrow/ModalCareer.vue'
  export default {
    name: 'JoinSparrow',
    components:{
      // JobTechnology,
      JobCompliance,
      // JobFinance,
      // JobMarketing,
      // JobHR,
      // JobTrading,
      modal,
    },
    data() {
      return {
        listPosition: [],
        isModalVisible: false,
        userSelected: null,
      }
    },
    mounted() {
      this.getCurrentPosition()
    },
    methods: {
      showModal(dt) {
        this.userSelected = dt
        this.isModalVisible = true;
        $("body").css("overflow-y", "hidden");
      },
      closeModal() {
        this.isModalVisible = false;
        $("body").css("overflow-y", "auto");
      },
      getCurrentPosition() {
        let hardCodeListPosition = [
          {
            namePos: 'Mobile App Developer',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Design, build, release, and maintain the Sparrow iOS application for digital asset trading.'},
                  {list:'Perform user requirement analysis and produce application design specifications.'},
                  {list:'Collaborate with the business and technology team for new application feature development, identify and fix app issues, and maintain the application source code.'},
                  {list:'Continually improve the robustness, security, and stability of the existing iOS app to support the ongoing platform functionality.'},
                  {list:'Define and conduct integration and unit testing.'},
                  {list:'Management of source code change control, configuration management, and process for build/deployment of app code.'},
                  {list:'Participate in quality assurance and user acceptance testing before and after the App release.'}
                ]
              }
            ],
            requirements:[
              {reqList:'At least 2 years of substantial iOS mobile app development experience.'},
              {reqList:'Proficient with Swift and XCode.'},
              {reqList:'Familiar with RESTful APIs, WebSocket, and mobile libraries to connect iOS applications to back-end services.'},
              {reqList:'Proficiency with iOS automated testing frameworks.'},
              {reqList:'Familiar with code version control, security, code review, and CI/CD frameworks.'},
              {reqList:'Strong problem-solving, analytical, project management, written and verbal communication skills.'},
              {reqList:'Experienced in Frontend JavaScript frameworks like React.js is a bonus.'},
              {reqList:'Familiarity with financial trading, accounting, and financial reporting is preferred.'},
              {reqList:'Experience working with a high-performing team of software engineers, using agile software development practices.'},
              {reqList:'Quick learning ability, strong teamwork, and communication skills.'},
            ]
          },
          {
            namePos: 'Head of Compliance',
            description: 'Key point of contact for oversight of local execution of the Enterprise Risk, and Compliance and Governance Management framework and systems.',
            duties: [
              {
                dutyList: 'Oversight & Leadership:',
                descriptionList:'',
                responsibilities:[
                  {list:'Establish and set the tone of risk and compliance throughout the organization.'},
                  {list:'Establish and maintain enterprise risk management, and compliance and governance systems that meet both the Company’s framework and local regulatory requirements.'}
                ]
              },
              {
                dutyList: 'Enterprise Risk Management framework and system:',
                descriptionList:'',
                responsibilities:[
                  {list:'Utilize the Company’s Enterprise Risk Management framework to conduct periodic risk assessment to drive real-time risk insights and intelligence to adequately manage the Company’s risks.'},
                  {list:'Execute risk and compliance assessments and recommendations to strengthen the control environment.'}
                ]
              },
              {
                dutyList: 'Compliance and Governance systems:',
                descriptionList:'',
                responsibilities:[
                  {list:'Ensure regulatory requirements are understood.'},
                  {list:'Partner with relevant stakeholders to establish and maintain compliance and governance systems to ensure compliance with local regulatory compliance requirements and prudential risks.'},
                  {list:'Identify potential areas of operational risk and compliance vulnerability, develop and implement solutions to resolve issues, and provide guidance for future avoidance of similar issues.'},
                  {list:'Support execution of regulatory change management initiatives, including drafting of key policies and guidelines.'}
                ]
              },
              {
                dutyList: 'Reporting:',
                descriptionList:'',
                responsibilities:[
                  {list:'Support in the preparation of internal management reporting and regulatory incident reporting.'},
                  {list:'Escalate timely and actionable information to key stakeholders.'}
                ]
              },
              {
                dutyList: 'Business Initiatives:',
                descriptionList:'',
                responsibilities:[
                  {list:'Provide guidance to business units relating to the development of controls for critical business processes to mitigate compliance/operational risks.'},
                  {list:'Serve as a credible business partner to executives and other key stakeholders.'},
                  {list:'Provide subject matter expertise to the business units in identifying, assessing, prioritizing, and managing risk and regulatory matters.'}
                ]
              },
              {
                dutyList: 'Regulatory Engagement:',
                descriptionList:'',
                responsibilities:[
                  {list:'Provide day-to-day management and support for all local regulatory interactions, examinations and ad-hoc requests.'}
                ]
              },
            ],
            requirements:[
              {reqList:'Bachelor’s degree in law, business administration, or relevant field.'},
              {reqList:'A minimum of 10 years’ experience, including demonstrated leadership in building risk, and compliance and governance frameworks.'},
              {reqList:'Deep knowledge of money service and/or digital token business and compliance requirements, including knowledge of anti-money laundering, data protection, and consumer protection regulations.'},
              {reqList:'At least 7 years of experience with people management, regulators and the regulatory exam process.'},
              {reqList:'Demonstrated ability to build and maintain relationships with key stakeholders, including strengths in influencing, conflict management, and negotiation.'},
              {reqList:'Ability to take ownership of outcomes and the ability to work with business unit teams to drive performance.'},
              {reqList:'Ability to operate with strong integrity on projects that are sensitive and confidential.'},
              {reqList:'Strong problem solving and operational process skills and attention to details.'},
              {reqList:'High energy and the desire to work in a growing and changing environment.'},
              {reqList:'Effective written and communication skills.'}
            ],
            specialReq:[
              {spReqList:'Strong computer knowledge.'},
              {spReqList:'Excellent in MS Word, Excel, Visio, and PowerPoint skills.'}
            ]
          },
          {
            namePos: 'Junior Accounts Executive',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Working in a young and dynamic team, you will be making a direct contribution to accounting functions.'},
                  {list:'Having full ownership over full sets of accounts, your task will be to ensure all.'},
                  {list:'Your task will also include keeping all accounting schedules up to date and accurate.'},
                  {list:'You might be involved with other ad-hoc processes as well as process implementation as required.'}
                ]
              },
            ],
            requirements:[
              {reqList:'Minimally, you will have a Diploma in Accounting or Accounting related fields.'},
              {reqList:'We welcome candidates without experience to apply.'},
              {reqList:'You should be able to cope with urgent requests and enjoy working in a fast-paced environment.'},
              {reqList:'You are already familiar with Microsoft office and are proficient in Microsoft Excel.'},
              {reqList:'Having experience with Quickbook or Quickbooks Online will give you an advantage.'},
              {reqList:'You are also a great team player who is out to make a difference in the Company and are equipped with good communication skills.'},
            ]
          },
          {
            namePos: 'IT System Engineer',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Providing IT technical support across company computer hardware, software, systems and networks.'},
                  {list:'Perform hardware and software installations, configurations and updates as needed.'},
                  {list:'Monitoring and maintaining computer systems and networks.'},
                  {list:'Responding in a timely manner to service issues and requests.'},
                  {list:'Maintain procedures and reports that provide technical support to the entire organization.'},
                  {list:'Support the implementation of new solutions or applications.'},
                  {list:'Test, evaluate, and make decisions about new technology for the business.'},
                  {list:'System administration in setup, managing, and maintaining company’s AWS Cloud infrastructure.'},
                  {list:'Monitoring Cloud infrastructure deployment, security process and operations by proactively monitoring and protecting system boundaries, application deployment and release status, automating manual tasks, keeping computer systems and network devices hardened against attacks.'},
                  {list:'Maintaining operating systems and doing regular maintenance and patching.'},
                  {list:'Proactive monitoring, react to alerts, troubleshoot faults and restore service when necessary, according to SLAs.'},
                ]
              },
            ],
            requirements:[
              {reqList:'At least 2 years of relevant working experience.'},
              {reqList:'Strong administration experience in Windows and Linux servers and network devices.'},
              {reqList:'Excellent problem solving, communication and people skills.'},
              {reqList:'Strong troubleshooting and critical thinking skills.'},
              {reqList:'Hands on experience in Cloud, Active Directory, Office 365, VMWare and Antimalware is preferred.'},
              {reqList:'Scripting and programming languages experience e.g. Python, bash, PowerShell etc.'},
              {reqList:'Experience with scripting and automation tools is desired.'},
              {reqList:'Certification in CCNA, AWS SysOps or equivalent is preferred.'}
            ]
          },
          {
            namePos: 'Product Manager',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Responsible for managing and growing Sparrow products.'},
                  {list:'Develop Sparrow product strategies from inception to completion of a product.'},
                  {list:'Create detailed business requirements documents and work closely with the development teams to ensure products meet the business needs.'},
                  {list:'Work closely with Sparrow business and engineering teams to develop the product; Sparrow marketing and sales teams to effectively market and drive Sparrow’s financial products.'},
                  {list:'Conduct business case analysis to model the product’s cost and potential margin.'},
                  {list:'Participate in quality assurance and user acceptance testing before product launch.'}
                ]
              },
            ],
            requirements:[
              {reqList:'Minimum of 5 years of product management or equivalent experience on financial trading platforms.'},
              {reqList:'In-depth understanding of options/futures markets, trading interfaces, order management systems, trading back-office systems, options/futures margin rules, and overall brokerage operations.'},
              {reqList:'Strong background in finance, marketing, and accounting.'},
              {reqList:'Strong problem-solving, analytical, project management, written and verbal communication skills.'},
              {reqList:'Experience working with a high-performing team of software engineers, using agile software development practices.'},
              {reqList:'Experience setting a vision and delivering complex projects involving many disciplines.'}
            ]
          },
          {
            namePos: 'Data Engineer',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Design, develop, and maintain the company data analytics platform for trade analysis and financial reporting.'},
                  {list:'Design and implement data dashboards and reports providing market insights, trade data consolidation, accounting reconciliation, risk exposures, backtesting, and more.'},
                  {list:'Develop, and maintain data tools and ETL pipelines to enable tracking of trading and financial data metrics trading, operations, and finance teams.'},
                  {list:'Setup and maintain Jupyter notebook platform for teams to interactively developing and presenting internal data projects.'},
                  {list:'Set up data infrastructure using data warehouse solutions like AWS Redshift.'},
                  {list:'Continually improve the robustness, security, and stability of the data analytics platform to support the ongoing data projects.'},
                  {list:'Collaborating with other team members on the integration of internal and external backend elements.'},
                  {list:'Define and conduct integration and unit testing.'},
                  {list:'Management of source code change control, configuration management, and  process for build/deployment of code.'},
                  {list:'Prevent, identify and resolve any anomalies from data services.'},
                  {list:'Troubleshooting and fault isolation to restore service when necessary.'},
                  {list:'Developing the necessary technical documentation and assistance tools for the data platform.'}
                ]
              },
            ],
            requirements:[
              {reqList:'3 years + of experience exclusively in a trading data domain and role.'},
              {reqList:'Qualifications in Computer Science, Statistics, Analytics or other relevant degrees.'},
              {reqList:'Experience working in database like PostgreSQL, MongoDB, etc.'},
              {reqList:'Demonstrable ability to build stable and reliable data pipelines.'},
              {reqList:'At least 2+ years of professional programming experience in Python/Golang and SQL.'},
              {reqList:'Experience developing machine learning models is an added advantage.'},
              {reqList:'Strong problem-solving, analytical, project management, written and verbal communication skills.'},
              {reqList:'Hands-on experience in AWS, Jupyter Notebook, Redis, Microservice architecture, Lambda, Docker, Cypress, Circle CI, etc is preferred.'},
              {reqList:'Familiarity with financial trading, accounting, and financial reporting is preferred.'},
              {reqList:'Experience working with a high-performing team of software engineers, using agile software development practices.'},
              {reqList:'Quick learning ability, strong teamwork and communication skills.'},
            ]
          },
          {
            namePos: 'Compliance Officer',
            description: '',
            duties: [
              {
                dutyList: 'Enterprise Risk Management framework and system:',
                descriptionList:'',
                responsibilities:[
                  {list:'Utilize the Company’s Enterprise Risk Management framework to conduct periodic risk assessment to drive real-time risk insights and intelligence to adequately manage the Company’s risks.'},
                  {list:'Execute risk and compliance assessments and recommendations to strengthen the control environment.'}
                ]
              },
              {
                dutyList: 'Compliance and Governance systems:',
                descriptionList:'',
                responsibilities:[
                  {list:'Ensure regulatory requirements relating to CMS License and Major Payment Institution are understood.'},
                  {list:'Partner with relevant stakeholders to ensure adherence with compliance and governance systems and compliance with local regulatory compliance requirements.'},
                  {list:'Support execution of regulatory change management initiatives, including drafting of key policies and guidelines.'},
                  {list:'Support in the enhancement of compliance and governance systems in identifying potential areas of operational risk and compliance vulnerability, developing and implementing solutions to resolve issues, and provide guidance for future avoidance of similar issues.'}
                ]
              },
              {
                dutyList: 'Reporting:',
                descriptionList:'',
                responsibilities:[
                  {list:'Support in the preparation of internal management reporting and regulatory incident reporting.'},
                  {list:'Escalate timely and actionable information to key stakeholders.'}
                ]
              },
              {
                dutyList: 'Business Initiatives:',
                descriptionList:'',
                responsibilities:[
                  {list:'Provide guidance to business units relating to the development of controls for critical business processes to mitigate compliance/operational risks.'},
                  {list:'Serve as a credible business partner to executives and other key stakeholders.'},
                  {list:'Provide subject matter expertise to the business units in identifying, assessing, prioritizing, and managing risk and regulatory matters.'}
                ]
              },
              {
                dutyList: 'Regulatory Engagement:',
                descriptionList:'',
                responsibilities:[
                  {list:'Provide day-to-day management and support for all local regulatory interactions, examinations and ad-hoc requests.'}
                ]
              },
            ],
            requirements:[
              {reqList:'Bachelor’s degree in law, business administration, or relevant field.'},
              {reqList:'A minimum of 5 years directly related experience in a legal or compliance role with a substantial knowledge of relevant rules and regulations and the day-to-day compliance affairs.'},
              {reqList:'Strong knowledge of Singapore’s AML/CFT legislation, Digital Payment Token (“DPT”) regulations, CMS License related regulations, Data Protection and other relevant regulations.'},
              {reqList:'Demonstrated ability to build and maintain relationships with key stakeholders, including strengths in influencing, conflict management, and negotiation.'},
              {reqList:'Ability to take ownership of outcomes and the ability to work with business unit teams to drive performance.'},
              {reqList:'Ability to operate with strong integrity on projects that are sensitive and confidential.'},
              {reqList:'Strong problem solving and operational process skills and attention to details.'},
              {reqList:'High energy and the desire to work in a growing and changing environment.'},
              {reqList:'Effective written and communication skills.'},
            ]
          },
          {
            namePos: 'Backend Engineer',
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Develop and maintain server-side applications with NodeJS to provide high performance APIs for digital asset trading and financial reporting.'},
                  {list:'Continually improve the robustness, security and stability of existing APIs to support the ongoing platform functionality.'},
                  {list:'Create new APIs services to the existing platform and administrative modules to support platform operations.'},
                  {list:'Collaborating with other team members on the integration of backend and frontend elements.'},
                  {list:'Define and conduct integration and unit testing.'},
                  {list:'Management of source code change control, configuration management and process for build/deployment of code.'},
                  {list:'Prevent, identify and resolve any anomalies from backend services.'},
                  {list:'Troubleshooting and fault isolation to restore service when necessary.'},
                  {list:'Developing the necessary technical documentation and assistance tools for the Sparrow platform.'}
                ]
              },
            ],
            requirements:[
              {reqList:'At least 2 years of substantial Node.js development experience.'},
              {reqList:'Extensive knowledge of JavaScript, web stacks, libraries, and Node.js frameworks such as Express, Socket.io etc.'},
              {reqList:'Experience working in database like PostgreSQL, MongoDB, etc.'},
              {reqList:'Strong problem-solving, analytical, project management, written and verbal communication skills.'},
              {reqList:'Experienced in Frontend JavaScript frameworks like React.js, AngularJS or Vue.JS is a bonus.'},
              {reqList:'Hands-on experience in AWS, Elasticsearch, Redis, Microservice architecture, Lambda, Docker, Cypress, Circle CI etc is preferred.'},
              {reqList:'Familiar with financial trading, accounting and financial reporting is preferred.'},
              {reqList:'Experience working with a high-performing team of software engineers, using agile software development practices.'},
              {reqList:'Quick learning ability, strong teamwork and communication skills.'},
            ]
          },
          // {
          //   namePos: 'Credit Risk Officer',
          //   description: '',
          //   duties: [
          //     {
          //       dutyList: 'Oversight & Leadership:',
          //       descriptionList:'',
          //       responsibilities:[
          //         {list:'Establish and set the tone of credit risk management throughout the organization.'},
          //         {list:'Establish and maintain credit risk management, internal control and compliance systems that meets both the company’s credit risk framework and local regulatory requirements.'},
          //       ]
          //     },
          //     {
          //       dutyList: 'Credit Risk Management framework and system:',
          //       descriptionList:'',
          //       responsibilities:[
          //         {list:'Establish Credit Risk Management framework to conduct periodic risk assessment to drive real-time risk insights and intelligence to adequately manage the company’s credit risk.'},
          //         {list:'Execute risk and compliance assessments and recommendations to strengthen the control environment.'},
          //       ]
          //     },
          //     //NANTI
          //     {
          //       dutyList: 'Credit Risk Management and Measurement systems:',
          //       descriptionList:'Establish credit risk management and measurement systems to govern the day-to-day running of the credit risk function, at minimum, supporting the following activities:',
          //       responsibilities:[
          //         {list:'a. Credit Risk Management and Measurement Framework and Tools',
          //          secList: [
          //            {secListText:'Assist in development and review of Credit Policy & Standards.'},
          //            {secListText:'Assist in development and implementation of credit risk management and measurement models and tools for credit rating, credit application processing and any other related activities.'}
          //          ]
          //         },
          //         {list:'b. Credit Applications',
          //           secList: [
          //             {secListText:'Give feedback on prospective and existing customer and partner requests to Trading, Sales and Operations Departments.'},
          //             {secListText:'Process credit applications and make recommendations, highlighting all risk issues pertinent to the credit decision.'},
          //             {secListText:'Justify credit decisions, both verbally and in writing.'},
          //             {secListText:'Ensure quick turnaround of credit requests and proper record maintenance.'}
          //           ]
          //         },
          //         {list:'c. Credit Portfolio Management',
          //           secList: [
          //             {secListText:'Develop and maintain a sound 360 degree understanding of the client and partner portfolio.'},
          //             {secListText:'Monitor the risk of client and partner portfolios to ensure they stay within agreed parameters of the Credit Policy and the Risk Appetite Statement.'}
          //           ]
          //         },
          //         {list:'d. Credit Research',
          //           secList: [
          //             {secListText:'Undertake detailed credit research into countries, industry/sectors, individual borrowers/counterparties in the company’s portfolio using financial and non-financial sources.'},
          //             {secListText:'Participate in customer and partner due diligence site visits, calls, and/or meetings. Prepare call reports for each visit/call/meeting within one week of the event.'}
          //           ]
          //         },
          //       ]
          //     },
          //     //
          //     {
          //       dutyList: 'Reporting:',
          //       descriptionList:'',
          //       responsibilities:[
          //         {list:'Support in the preparation of internal management reporting and regulatory incident reporting.'},
          //         {list:'Escalate timely and actionable information to key stakeholders.'}
          //       ]
          //     },
          //     {
          //       dutyList: 'Business Initiatives:',
          //       descriptionList:'',
          //       responsibilities:[
          //         {list:'Provide guidance to business units relating to the development of controls for critical business processes to mitigate credit risk.'},
          //         {list:'Serve as a credible business partner to executives and other key stakeholders.'},
          //         {list:'Provide subject matter expertise to the business in identifying, assessing, prioritizing, and managing credit risk and regulatory matters.'}
          //       ]
          //     },
          //     {
          //       dutyList: 'Regulatory Engagement:',
          //       descriptionList:'',
          //       responsibilities:[
          //         {list:'Provide day-to-day management and support for all local regulatory interactions, examinations and ad-hoc requests.'}
          //       ]
          //     },
          //   ],
          //   requirements:[
          //     {reqList:'Bachelor’s degree in business administration in either of Finance, Business, Economics.'},
          //     {reqList:'A minimum of 7 years credit risk experience gained within a banking environment and proven ability in credit risk identification and control.'},
          //     {reqList:'Knowledge of digital token business and compliance requirements would be an added advantage.'},
          //     {reqList:'Demonstrated ability to build and maintain relationships with key stakeholders, including strengths in influencing, conflict management, and negotiation.'},
          //     {reqList:'Ability to take ownership of outcomes and the ability to work with business unit teams to drive performance.'},
          //     {reqList:'Ability to operate with strong integrity on projects that are sensitive and confidential.'},
          //     {reqList:'Strong problem solving and operational process skills and attention to details.'},
          //     {reqList:'High energy and the desire to work in a growing and changing environment.'},
          //     {reqList:'Effective written and communication skills.'}
          //   ]
          // },

        ]

        this.listPosition = hardCodeListPosition
      }
    },
  }
</script>
