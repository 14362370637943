<template>
  <div class="c-career-modal">
    <div class="c-career-modal__mask">
      <div class="c-career-modal__wrapper">
        <div class="c-career-modal__container" v-click-outside="close">

          <div class="c-career-modal__header">
            <img class="c-career-modal__close-modal" src="@/assets/images/close.svg" alt="close-modal" @click="close">
            {{namePos}}
          </div>

          <div class="c-career-modal__divider"></div>

          <div class="c-career-modal__body">
            <p class="c-career-modal__shortdesc" v-html="dataJobs.shortDesc"></p>
            <p class="c-career-modal__subtitle">Description</p>
            <p :class="{'c-career-modal__body-responsibility-description': description!=''}">{{description}}</p>
            <div class="c-career-modal__body-responsibility">
              <ol>
                <div class="c-career-modal__body-responsibility-dutylist" :class="{'c-career-modal__body-responsibility-list-nondutylist': dt.dutyList==''}" v-for="(dt,index) in duties" :key="index">
                <li class="c-career-modal__body-responsibility-dutylist-dec" v-if="dt.dutyList!=''">{{dt.dutyList}}</li>
                <p :class="{'c-career-modal__body-responsibility-desclist': dt.descriptionList!=''}">{{dt.descriptionList}}</p>
                  <div class="c-career-modal__body-responsibility-list" :class = "{'c-career-modal__body-responsibility-list-nondutylist': dt.dutyList!='', 'c-career-modal__body-responsibility-list-secList': dataDetail.secList}" v-for="(dataDetail,indexDetail) in dt.responsibilities" :key="indexDetail">
                    <p class="c-career-modal__body-responsibility-list-bullets" v-if="!dataDetail.secList">•</p>
                    <p>{{dataDetail.list}}</p>
                    <div class="c-career-modal__body-responsibility-list-secListItem" v-for="(dataList,indexList) in dataDetail.secList" :key="indexList">
                      <p class="c-career-modal__body-responsibility-list-bullets">•</p>
                      <p>{{dataList.secListText}}</p>
                    </div>
                  </div>
                </div>
              </ol>
            </div>
            <p class="c-career-modal__subtitle">Requirements</p>
            <div class="c-career-modal__body-responsibility">
              <div class="c-career-modal__body-responsibility-list c-career-modal__body-responsibility-list2" v-for="(dt,index) in requirements" :key="index">
                <p class="c-career-modal__extrabold">{{dt.reqType}}</p>
                <ul class="c-career-modal__body-responsibility-unorderlist2">
                  <li class="c-career-modal__body-requirements-list" v-for="(data,idx) in dt.requireList" :key="idx"><span class="c-career-modal__body-responsibility-unorderlist-text">{{data.reqList}}</span></li>
                </ul>
              </div>
            </div>
            <p class="c-career-modal__subtitle" v-if="spReq">Special requirement</p>
            <div class="c-career-modal__body-requirements">
              <div class="c-career-modal__body-responsibility-list" v-for="(data,index) in spReq" :key="index">
                <ul class="c-career-modal__body-responsibility-unorderlist">
                  <li><span class="c-career-modal__body-responsibility-unorderlist-text">{{data.spReqList}}</span></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import vClickOutside from 'v-click-outside'
  export default {
    name: 'ModalCareer',
    props:{
      duties: Array,
      requirements: Array,
      namePos: String,
      spReq: Array,
      description: String,
      dataJobs: Array,
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }

</script>
