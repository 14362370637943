<template>
  <div class="c-join-sparrow__job-list">
    <div class="c-join-sparrow__job-item">
      <div class="c-faq-reorganize__list-head" @click="toggleHead()">
        <p class="c-faq-reorganize__list-head-text">Compliance</p>
        <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-down.svg" v-if="!headClicked">
        <img class="c-faq-reorganize__list-head-img" src="@/assets/images/arrow-up.svg" v-else>
      </div>
      <div class="c-join-sparrow__job-body" :class="{'c-join-sparrow__job-body_active': headClicked}">
        <div class="c-join-sparrow__job-body-list" v-for="(data, index) in listPosition" :key="index" @click="showModal(data)">
          {{data.namePos}}
        </div>
      </div>
    </div>
    <modal v-if="isModalVisible" :dataJobs = "userSelected" :duties="userSelected.duties" :requirements="userSelected.requirements" :namePos="userSelected.namePos" :spReq="userSelected.specialReq" :description="userSelected.description" @close="closeModal">
    </modal>
  </div>
</template>
<script>
  import $ from 'jquery'
  import modal from '@/components/peopleatsparrow/ModalCareer.vue'
  export default {
    name: 'JobTechnology',
    components:{
      modal,
    },
    data(){
      return{
        listPosition: [],
        isModalVisible: false,
        headClicked: false,
        userSelected: null
      }
    },
    mounted() {
      this.getCurrentPosition()
    },
    methods: {
      toggleHead() {
        this.headClicked = !this.headClicked
      },
      showModal(dt) {
        this.userSelected = dt
        this.isModalVisible = true;
        $("body").css("overflow-y", "hidden");
      },
      closeModal() {
        this.isModalVisible = false;
        $("body").css("overflow-y", "auto");
      },
      getCurrentPosition() {
        let hardCodeListPosition = [
          {
            namePos: 'Head of Compliance (Singapore)',
            shortDesc: "We are looking to hire a highly motivated and intellectually curious individual with strong interest in fintech, digital assets, payments space. Based in Singapore, the successful candidate would head up and lead the Compliance team and drive the firm's compliance program and culture.<br><br>The ideal candidate would be a self-starter with strong interpersonal/communication skills, exercise sound judgment and be able to thrive in a dynamic work environment.",
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Establish and maintain effective regulatory and AML compliance framework and internal controls in the firm.'},
                  {list:'Oversee and manage regulatory and AML risk assessments to strengthen internal controls and ensure compliance with local regulations.'},
                  {list:'Utilize the Company’s Enterprise Risk Management framework to conduct periodic risk assessment to drive real-time risk insights and intelligence to adequately manage the Company’s risks.'},
                  {list:'Serve as the point of contact with local regulators and drive the regulatory engagement efforts.'},
                  {list:'Work with internal stakeholders to ensure compliance with all applicable regulatory and licensing requirements and conditions.'},
                  {list:'Keep abreast of the latest regulatory developments in Singapore pertaining to payments services and digital assets/financing and advise management team on emerging regulatory and compliance issues.'},
                  {list:'Manage regulatory and/or external audits on the firm.'},
                ]
              },
            ],
            requirements:[
              {
                reqType: '',
                requireList: [
                  {reqList:"Bachelor's degree in accounting, finance, economics, law or equivalent work experience."},
                  {reqList:'At least 10 years of relevant working experience in compliance or related field.'},
                  {reqList:'Strong knowledge of regulatory requirements in Singapore and the Payment Services Act in particular.'},
                  {reqList:'Prior experience dealing with regulators and/or working at a regulatory body would be a plus.'},
                  {reqList:'Knowledge and expertise in payments/fintech industry would be a plus.'},
                  {reqList:'Strong communication and interpersonal skills with prior experience in managing a team.'},
                ]
              },
            ],
          },
          {
            namePos: 'Compliance Officer (Singapore)',
            shortDesc: "We are looking to hire a highly motivated, intellectually curious and team-oriented individual with strong interest in fintech, digital assets, payments space. Based in Singapore and reporting to the Head of Compliance, the successful candidate would have the opportunity to shape and influence the firm's compliance program and culture.<br><br>The ideal candidate would be a self-starter with strong interpersonal/communication skills, exercise sound judgment and be able to thrive in a dynamic work environment.",
            description: '',
            duties: [
              {
                dutyList: '',
                descriptionList:'',
                responsibilities:[
                  {list:'Help establish and maintain effective regulatory and AML compliance framework and internal controls in the firm.'},
                  {list:'Conduct regulatory and AML risk assessments to strengthen internal controls and ensure compliance with local regulations.'},
                  {list:'Provide support for regulatory engagement and liaison with regulators and other government agencies.'},
                  {list:'Work with internal stakeholders to ensure compliance with all applicable regulatory and licensing requirements and conditions.'},
                  {list:'Keep abreast of the latest regulatory developments in Singapore pertaining to payments services and digital assets/financing.'},
                  {list:'Provide support in managing regulatory and/or external audits on the firm.'},
                ]
              },
            ],
            requirements:[
              {
                reqType: '',
                requireList: [
                  {reqList:"Bachelor's degree in accounting, finance, economics, law or equivalent work experience."},
                  {reqList:'At least 3 years of relevant working experience in compliance or related field.'},
                  {reqList:'Strong knowledge of regulatory requirements in Singapore and the Payment Services Act in particular.'},
                  {reqList:'Prior experience dealing with regulators and/or working at a regulatory body would be a plus.'},
                  {reqList:'Knowledge and expertise in the payments/fintech industry would be a plus.'},
                  {reqList:'Team player with strong communication and interpersonal skills.'},
                ]
              },
            ],
          },          
        ]

        this.listPosition = hardCodeListPosition
      }
    }
  }
</script>
